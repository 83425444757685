import { alpha, Card, CardActionArea, CardContent, Grid, Typography, useTheme } from '@mui/material';
import type { FC } from 'react';
import CarFigure from "../assets/images/car_figure.png";
import { Vehicle, formatFrenchVehiclePlate } from '@movalib/movalib-commons';

interface VehicleSimpleCardProps {
    vehicle:Vehicle,
    focused?:boolean,
    onClick: (vehicle: Vehicle) => void;
}

const VehicleSimpleCard: FC<VehicleSimpleCardProps> = ({ vehicle, focused = false, onClick }) => {

    const theme = useTheme();

    const handleOnClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if(onClick)
            onClick(vehicle);
    }
    return (
        <>
           { vehicle && 
                <CardActionArea onClick={handleOnClick} sx={{ maxWidth: 345, flex: 'none' }} >
                    <Card variant='outlined' sx={{ maxWidth: 345, 
                        backgroundColor: focused ?  alpha(theme.palette.primary.light, 0.7) :'white', 
                        overflow: 'visible', mt: 2 }}
                    >
                        <img src={CarFigure} style={{
                            position: 'relative',
                            width: '50%',
                            top: '-25px',
                            zIndex: 200}} alt='Icone Voiture'>
                        </img>
                        <CardContent sx={{ pt: 0, pb: 0}}>
                            <Typography variant="h6" component="div" align="center" sx={{ mb:1 }} color={focused ? theme.palette.primary.contrastText : 'gray'}>
                                {vehicle.brand && `${vehicle.brand} `}
                                {vehicle.model && `${vehicle.model} `}
                                {vehicle.version && `${vehicle.version}`}
                            </Typography>
                            <Grid container justifyContent="space-between" >
                                <Typography variant="subtitle1" color={focused ? theme.palette.primary.contrastText : 'gray'}>
                                {formatFrenchVehiclePlate(vehicle.plate)}
                                </Typography>
                                <Typography variant="subtitle1" color={focused ? theme.palette.primary.contrastText : 'gray'}>
                                {vehicle.currentMileage} km
                                </Typography>
                            </Grid>
                        </CardContent>
                    </Card>
                </CardActionArea>
            }
        </>
    );
}

export default VehicleSimpleCard;
