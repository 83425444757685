import React, { CSSProperties, Fragment, FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import VehicleService from '../services/VehicleService';
import { Alert, AppBar, FormControl, FormHelperText, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Skeleton, TextField, Toolbar, Typography, darken, formControlClasses, useMediaQuery, useTheme } from '@mui/material';
import { MovaDialog, MovaFormField, VehiclePlateField, validateField } from '@movalib/movalib-commons';
import GreenLeafImage from "../assets/images/leaf_green_large.png";
import UserService from '../services/UserService';
import { setSnackbar } from '../slices/snackbarSlice';
import { useDispatch } from 'react-redux';
import CloseIcon from '@mui/icons-material/CloseRounded';
import { LoadingButton } from '@mui/lab';

interface AddVehicleDialogProps {
  open: boolean,
  isFirstVehicle: boolean,
  onConfirm: (returnVehiclePlate: string, returnVehicleDescription: string) => void,
  onClose?: () => void;
  title?: string;
  titleStyle?: CSSProperties,
  leafImageColor?: "green" | "pink" | "yellow"
}

type VehicleForm = {
  vehiclePlate: MovaFormField,
  currentMileage: MovaFormField,
  averageMileagePerYear: MovaFormField
}

const initialFormState = {
  vehiclePlate: { value: '', error: '', isValid: true },
  currentMileage: { value: '', error: '', isValid: true },
  averageMileagePerYear: { value: '', error: '', isValid: true }
};

const AddVehicleDialog: FunctionComponent<AddVehicleDialogProps> = ({ open, isFirstVehicle = false, onConfirm, onClose, title, titleStyle, leafImageColor = "green" }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const [form, setForm] = useState<VehicleForm>(initialFormState);
  const [vehicleDescription, setVehicleDescription] = useState<string | undefined>(undefined);
  const [vehiclePlate, setVehiclePlate] = useState<string | undefined>("");
  const [message, setMessage] = useState<string>("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingPlate, setLoadingPlate] = useState<boolean>(false);

  // Cette variable sert uniquement à éviter les appels multiple à VehicleService.getVehicleDescription (la maj du state étant asynchrone)
  const lastVehiclePlate = useRef('');

  useEffect(() => {
    purgeLocalState();
  }, [open]);

  useEffect(() => {
    validateForm();
  }, [form, vehicleDescription])

  const handleValidVehiclePlate = (validVehiclePlate: string) => {

    // Permet déviter des appels multiple à l'API le temps de la MAJ du state local
    if (lastVehiclePlate.current === validVehiclePlate) return; // Sortir tôt si la valeur est la même
    lastVehiclePlate.current = validVehiclePlate;

    // On récupère en state local la plaque d'immat
    setVehiclePlate(validVehiclePlate);

    setForm(prevForm => (
      { ...prevForm, ["vehiclePlate"]: { ...prevForm["vehiclePlate"], value: validVehiclePlate, isValid: true } }));
      setLoadingPlate(true);
    // Récupération des infos véhicule depuis l'API
    VehicleService.getVehicleDescription(dispatch, validVehiclePlate)
      .then((response) => {
        setVehicleDescription(response ? response : undefined);

      }).catch(error => {
        console.error(error);
        setVehicleDescription(undefined);
        setMessage("Erreur lors de la recherche du véhicule.");
      }).finally(() => {
        setLoadingPlate(false);
      });
  };

  const validateForm = () => {

    let newForm: VehicleForm = { ...form };

    // Validator pour les champs obligatoires
    newForm.vehiclePlate = validateField(form.vehiclePlate, value => !!value, 'Champ obligatoire');
    //newForm.currentMileage = validateField(form.currentMileage, value => !!value, 'Champ obligatoire');

    setForm(newForm);

    // Lors du contrôle de validité, si une description véhicule est présente c'est que la plaque est valide ;)
    const isValid = Boolean(newForm.vehiclePlate.isValid && vehicleDescription);
    setIsFormValid(isValid);

    return isValid;
  }

  const purgeLocalState = () => {
    setVehicleDescription(undefined);
    setVehiclePlate(undefined);
    setMessage("");
  }

  const handleOnConfirm = () => {

    // Création du véhicule pour l'utilisateur courant
    if (validateForm()) {

      // Création de la requête
      let req = {
        plate: form.vehiclePlate.value,
        currentMileage: form.currentMileage.value,
        averageMileagePerYear: form.averageMileagePerYear.value,
      }
      setLoading(true);
      UserService.addUserVehicle(dispatch, req)
        .then((response) => {

          purgeLocalState();

          // Affichage notification utilisateur
          dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

          onConfirm(vehiclePlate ? vehiclePlate : '', vehicleDescription ? vehicleDescription : '');

        }).catch(error => {
          console.error(error);
          setMessage("Erreur lors de l'ajout de votre véhicule.");
        }).finally(() => {
          setLoading(false);
        });
    }
  }

  const handleSelectChange = (e: SelectChangeEvent<string>): void => {
    handleChange(e.target.name, e.target.value);
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    handleChange(e.target.name, e.target.value);
  }

  const handleChange = (fieldName: string, fieldValue: string): void => {
    const newField: MovaFormField = { [fieldName]: { value: fieldValue, isValid: true } };

    setForm({ ...form, ...newField });
  }

  const handleOnClose = () => {
    purgeLocalState();
    if (onClose) {
      onClose();
    }
  }

  const getHeaderText = () => {
    if (isFirstVehicle) {
      return <>
        <Typography variant="subtitle1" sx={{ fontWeight: 500, textAlign: 'center', mb: 3 }}>
          Pour commencer l'aventure Movalib, ajoutez votre premier véhicule 😉</Typography>
      </>
    } else {
      return <>
        <Typography variant="subtitle1" sx={{ fontWeight: 500, textAlign: 'center', mb: 3 }}>
          Renseignez l'immatriculation du véhicule, son kilométrage actuel et votre kilométrage moyen annuel
        </Typography>
      </>
    }
  }

  const getTitleStyle = (): CSSProperties => {
    if (titleStyle)
      return titleStyle;

    if (isFirstVehicle) {
      return {
        color: darken(theme.palette.secondary.main, 0.4),
        fontFamily: 'Dancing Script, cursive',
        fontSize: 50
      }
    } else {
      return {
        fontSize: 20,
      }
    }
  }

  const getTitle = (): string => {
    if (title)
      return title;
    else {
      return isFirstVehicle ? "Hello !" : "NOUVEAU VEHICULE";
    }
  }

  return (

    <MovaDialog fullScreen={isMobile} open={open} onClose={handleOnClose} leafImageColor={leafImageColor}
      title={getTitle()}
      titleStyle={getTitleStyle()}
      actions={
        <>
          {loading ? (
            <LoadingButton sx={{ width: '90%', height: '36px' }} loading={loading} variant="contained" disabled />
          ) : (
            <Button onClick={handleOnConfirm}
              color="primary" sx={{ width: '90%' }}
              disabled={!isFormValid}
              variant='contained'>
              Ajouter ce véhicule
            </Button>
          )}

        </>
      }
    >
      <DialogContent sx={{ zIndex: 3000, p: 0, pt: 2 }}>
        {getHeaderText()}
        <VehiclePlateField onValidVehiclePlate={handleValidVehiclePlate} />
        {loadingPlate && <Skeleton
          sx={{ borderRadius: 2, mx: 'auto', mt: 2 }}
          variant="rectangular"
          width={550}
          height={70}
        />}
        <DialogContentText id="add-vehicle-dialog-description" align='center' sx={{ paddingTop: 2 }}>
          {vehicleDescription &&
            <Fragment>
              <Typography variant='h6' color={theme.palette.primary.main} sx={{ fontSize: '16px' }}>
                <b>{vehiclePlate}</b>, véhicule trouvé
              </Typography>
              <Typography variant='body2' color={theme.palette.text.primary} sx={{ mt: 1 }}>
                <b>{vehicleDescription}</b>
              </Typography>
            </Fragment>
          }
        </DialogContentText>
        <TextField
          label="Kilométrage actuel"
          name="currentMileage"
          variant="outlined"
          type="number"
          value={form.currentMileage.value}
          onChange={(e) => handleInputChange(e)}
          error={(Boolean(vehicleDescription && form.currentMileage.error && form.currentMileage.value > 0))}
          sx={{
            width: '100%',
            mt: 3,
            '& input': { textTransform: 'uppercase' } // CSS pour forcer les majuscules dans l'input
          }}
          helperText={Boolean(vehicleDescription && form.currentMileage.error && form.currentMileage.value > 0)
            ? form.currentMileage.error : "Sur votre tableau de bord 😉"}
        />
        <FormControl fullWidth margin="normal" error={Boolean(form.averageMileagePerYear.error)}>
          <InputLabel id="averageMileagePerYear-label">Kilométrage moyen annuel</InputLabel>
          <Select
            labelId="averageMileagePerYear-label"
            id="averageMileagePerYear"
            name="averageMileagePerYear"
            value={form.averageMileagePerYear.value}
            onChange={e => handleSelectChange(e)}
            label="Kilométrage moyen annuel"
          >
            <MenuItem value="5000">5000</MenuItem>
            <MenuItem value="10000">10000</MenuItem>
            <MenuItem value="15000">15000</MenuItem>
            <MenuItem value="20000">20000</MenuItem>
            <MenuItem value="25000">25000</MenuItem>
            <MenuItem value="30000">30000</MenuItem>
            <MenuItem value="+30000">+30000</MenuItem>
          </Select>
          <FormHelperText>{form.averageMileagePerYear.error}</FormHelperText>
        </FormControl>
        {message && <Alert severity="error" sx={{ mb: 2 }}>{message}</Alert>}
      </DialogContent>

    </MovaDialog>
  );
}

export default AddVehicleDialog;