import { Alert, Box, Button, Card, CardContent, Grid, Link, TextField, Typography, darken, lighten, useMediaQuery, useTheme } from '@mui/material';
import { useState, type FC, CSSProperties, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/CancelRounded';
import { calculateTotalDowntimeRequested, calculateTotalDowntimeRequestedByPrestations, flexCenter, flexStart, formatEurosCurrency, getAlertSeverity, getEventStateColor, getEventStateLabel, isCancellableAppointment, isValidatedAppointment } from '../../helpers/Tools';
import AppointmentsIcon from '@mui/icons-material/TodayRounded';
import UpdateIcon from '@mui/icons-material/UpdateRounded';
import { DateFormatTypes, formatDateByCountryCode } from '../../helpers/DateUtils';
import { ConfirmationDialog, Event, EventState, Garage, MovaDialog, Document, DocumentType, DocumentState, MovaFormField, validateText, getApplicationsShortLabels } from '@movalib/movalib-commons';
import GarageSimpleCard from '../../components/GarageSimpleCard';
import GarageService from '../../services/GarageService';
import { setSnackbar } from '../../slices/snackbarSlice';
import UserService from '../../services/UserService';
import Loader from '../../components/Loader';
import Logger from '../../helpers/Logger';
import GarageDialog from '../GarageDialog';
import SmsIcon from '@mui/icons-material/SmsOutlined';
import CarRentalIcon from '@mui/icons-material/CarRental';
import CarCrashIcon from '@mui/icons-material/CarCrash';
import PaymentAuthorizationImg from '../../assets/images/payment_authorization.png';
import AppointmentIcon from '../../components/AppointmentIcon';
import { LoadingButton } from '@mui/lab';

interface AppointmentDetailsDialogProps {
    open: boolean,
    appointment: Event,
    onClose: (refresh: boolean) => void;
}

type Form = {
    comment?: MovaFormField
}

const initialFormState = {
    comment: { value: '' },
};

const AppointmentDetailsDialog: FC<AppointmentDetailsDialogProps> = ({ open, appointment, onClose }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const dispatch = useDispatch();
    const history = useHistory();
    const [localOpen, setLocalOpen] = useState<boolean>(open);
    const [garage, setGarage] = useState<Garage | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [openConfirmAppointmentCancel, setOpenConfirmAppointmentCancel] = useState(false);
    const [openGarageDialog, setOpenGarageDialog] = useState<boolean>(false);
    const [selectedGarage, setSelectedGarage] = useState<string>("");
    const [localAppointment, setLocalAppointment] = useState<Event>(appointment);
    const [quote, setQuote] = useState<Document | undefined>(undefined);
    const [invoice, setInvoice] = useState<Document | undefined>(undefined);
    const [form, setForm] = useState<Form>(initialFormState);
    const [openRejectQuote, setOpenRejectQuote] = useState<boolean>(false);


    const dialogTitleStyle: CSSProperties = {
        color: theme.palette.text.primary,
        fontWeight: 400
    }

    useEffect(() => {

        //refreshGarage();
        setLocalOpen(open);

         // Chargement de l'événnement détaillé dans un objet local
         if(appointment !== undefined){
            getEventDetails();

            // On crée un objet Garage fictif chargé avec les données issues du RDV (pour affichage d'une carte de coordonnées simplifiée)
            let garage = new Garage(String(appointment.garageId), '', appointment.garageName, appointment.garageAddress!, 0,
                [], [], '', [], false, false,0, 'Europe/Paris');
            setGarage(garage);
        }

    }, [open]);

    useEffect(() => {

        // Nous prenons le premier document de type "USER_APPOINTMENT_QUOTE", car il ne peut y avoir qu'un devis actif
        if (localAppointment !== undefined) {
            setQuote(localAppointment.documents?.filter(
                doc => doc.type === DocumentType.USER_APPOINTMENT_QUOTE
            )[0]);
        }

        // Nous prenons le premier document de type "VEHICLE_MAINTENANCE_INVOICE", car il ne peut y avoir qu'une facture active
        if (localAppointment !== undefined) {
            setInvoice(localAppointment.documents?.filter(
                doc => doc.type === DocumentType.VEHICLE_MAINTENANCE_INVOICE
            )[0]);
        }

    }, [localAppointment]);


    const getEventDetails = () => {

        if (appointment) {
            setLoading(true);

            // Récupération des infos détaillées du garage associé au rendez-vous
            UserService.getUserAppointment(dispatch, String(appointment.id))
                .then(event => {

                    if (event) {
                        Logger.info(event);
                        setLocalAppointment(event);
                    }

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }

    }

   /*  const refreshGarage = () => {

        if(appointment){
            setLoading (true);

            // Récupération des infos détaillées du garage associé au rendez-vous
            GarageService.getGarageInfos(dispatch, String(appointment.garageId))
                .then(garage => {

                    if(garage){
                        setGarage(garage);
                    } else {
                        setGarage(undefined);
                    }

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    } */
    
    const purgeLocalState = () => {
        setGarage(undefined);
    }

    const handleOnClose = () => {
        purgeLocalState();
        setLocalOpen(false);
        onClose(false);
    }

    const handleOnClickCancel = () => {
        setOpenConfirmAppointmentCancel(true);
    }

    const handleCloseConfirmAppointmentCancel = () => {
        setOpenConfirmAppointmentCancel(false);
    }

    const handleConfirmAppointmentCancel = () => {
        setOpenConfirmAppointmentCancel(false);

        if (appointment) {
            setLoading(true);

            // Annulation du rendez-vous par le client
            UserService.cancelUserEvent(dispatch, appointment.id)
                .then(response => {

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));

                    // Fermeture de la boite de dialogue
                    setLocalOpen(false);
                    onClose(true);

                }).catch(error => {
                    Logger.error(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleOnClickGarage = (garageId: string) => {
        if (garageId) {
            setSelectedGarage(garageId);
            setLocalOpen(false);
            setOpenGarageDialog(true);
        }
    }

    const handleOnCloseGarageDialog = () => {
        setOpenGarageDialog(false);
        setLocalOpen(true);
    }

    const handleOnApproveQuote = () => {
        if (localAppointment.quoteAmount || quote) {
            setLoading(true);

            UserService.scheduleAppointment(dispatch, localAppointment.id)
                .then(response => {

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
                    getEventDetails();

                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }

    const handleOnRejectQuote = () => {
        setOpenRejectQuote(true);
    }

    const handleCancelRejectQuote = () => {
        setOpenRejectQuote(false);
        setForm(prevForm => (
            { ...prevForm, ["comment"]: { ...prevForm["comment"], value: '', isValid: true, error: '' } }));
    }

    const validateForm = () => {

        let newForm: Form = form;
        let newField: MovaFormField;

        // Validator 'comment'
        if (form.comment?.value && !validateText(form.comment?.value)) {
            newField = { value: form.comment?.value, error: 'Le commentaire est invalide.' };
        } else if (form.comment?.value === '') {
            newField = { value: form.comment?.value, error: "Commentaire obligatoire." };
        } else {
            newField = { value: form.comment?.value, error: '' };
        }
        newForm = { ...newForm, ...{ comment: newField } };

        setForm(newForm);

        return !Boolean(newForm.comment?.error);
    }

    const handleConfirmRejectQuote = () => {
        if (localAppointment.quoteAmount || quote) {
            setLoading(true);

            UserService.rejectAppointment(dispatch, localAppointment.id)
                .then(response => {

                    // Affichage notification utilisateur
                    dispatch(setSnackbar({ open: true, message: response, severity: 'success' }));
                    getEventDetails();
                    setOpenRejectQuote(false);
                }).catch(error => {
                    Logger.info(error);
                    dispatch(setSnackbar({ open: true, message: error, severity: 'error' }));
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }


    const handleResendPaymentAuthorization = (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const req = {
            appointmentId: appointment.id,
        };

        setLoading(true);
        UserService.resendPaymentAuthorization(req).then(
            (response) => {
                if (response.success) {
                    dispatch(setSnackbar({ open: true, message: response.data ?? "Une nouvelle demande d'empreinte bancaire vous a été envoyée par email.", severity: 'success' }));
                } else {
                    dispatch(setSnackbar({ open: true, message: response.data ?? "Erreur lors de l'envoi de la demande.", severity: 'error' }));
                }
        }).catch(error => {
            Logger.info("Une erreur est survenue :", error);
            dispatch(setSnackbar({ open: true, message: "Erreur lors de l'envoi de la demande.", severity: 'error' }));
        })
        .finally(() => {
            setLoading(false);
        });
    }


    return (
        <>
            {localAppointment &&
                <MovaDialog fullScreen={isMobile} open={localOpen} onClose={handleOnClose} leafImageColor="green"
                    title="VOTRE RENDEZ-VOUS" titleStyle={dialogTitleStyle}
                    actions={
                        /** Annulation possible selon le statut du RDV */
                        isCancellableAppointment(localAppointment) &&
                        <Button onClick={handleOnClickCancel} color="error" sx={{ width: '90%' }} variant='text'>
                            <CancelIcon sx={{ mr: 1 }} />ANNULER LE RDV
                        </Button>
                    }
                >
                    <Box>
                        {/** Rappel infos du RDV */}
                        <Card variant='outlined' sx={{ overflow: 'visible', my: 1, borderColor: getEventStateColor(localAppointment.state) }}>
                            <CardContent sx={{ pt: 2, pb: 0 }}>
                                {/** Rappel du créneau */}
                                <Typography variant="h6" align="center" sx={{ fontSize: '18px', mb: 1 }} color="text.primary" >
                                    <b>{formatDateByCountryCode(localAppointment.vehicleDepositDate ?? localAppointment.start, 'fr', DateFormatTypes.LONG_FORMAT_DATETIME_LITERAL)}</b>
                                </Typography>
                                {localAppointment.vehicle &&
                                    <Typography sx={{ mb: 1, mt: 1 }} variant="subtitle2" component="div" align="center" color="text.primary" >
                                        <b>{localAppointment.vehicle.brand} {localAppointment.vehicle.model} {localAppointment.vehicle.version}</b>
                                    </Typography>
                                }
                                <Typography variant="h6" color="text.primary" sx={{ textAlign: 'center', fontSize: '16px' }}>
                                    {localAppointment.title}
                                </Typography>
                                {localAppointment.prestations && localAppointment.prestations.map((prestation, index) => (
                                    prestation.code === 'OTHER' && <Grid item xs={12} key={(index + 1) * 50}>
                                        <Typography variant="h6" color="text.primary" sx={{ textAlign: 'center', fontSize: '16px' }}>
                                            {localAppointment.notes}
                                        </Typography>
                                    </Grid>
                                ))}
                                {appointment.garageVehicleId && <Alert icon={<></>}
                                    sx={{ my: 1, width: '90%', borderRadius: '10px' }} style={flexCenter}>
                                    <Typography variant='body2' style={flexCenter}>
                                        <CarRentalIcon sx={{ width: '25px', height: '25px', mr: 1 }} />Un véhicule de prêt vous attend pour ce rendez-vous.<br />
                                    </Typography>
                                </Alert>}

                                {appointment.garageVehicleId === null && appointment.garageVehicleRequest && 
                                <Alert icon={<></>}
                                    sx={{ my: 1, width: '90%', borderRadius: '10px', backgroundColor: 'rgba(252, 222, 200, 1)'
                                    }} style={flexCenter}>
                                    <Typography variant='body2' style={flexCenter}>
                                        <CarCrashIcon sx={{ width: '25px', height: '25px', mr: 1 }} />
                                        { appointment.state !== EventState.NEW ? 'Malheureusement, aucun véhicule de prêt n\'est disponible pour ce rendez-vous.' : 'Affectation d’un véhicule de prêt en attente de confirmation garage …'}<br />
                                    </Typography>
                                </Alert>}
                     
                                {/** Rappel des prestations sélectionnées */}
                                {(localAppointment.state === EventState.SCHEDULED || localAppointment.state === EventState.COMPLETED) &&
                                    <Grid container padding={0} sx={{ mt: 2 }}>
                                        <Grid item xs={12} >
                                            <Alert className='styled-alert' icon={<></>}
                                                sx={{ mb: 2, width: '90%' }} style={flexCenter}>
                                                <Typography variant='body2' style={flexCenter}>
                                                    <UpdateIcon sx={{ width: '25px', height: '25px', mr: 1 }} />RESTITUTION VÉHICULE <br />
                                                </Typography>
                                                <Typography variant='body2' sx={{ mt: 1 }}>
                                                    <b>Votre garagiste vous enverra un SMS</b>
                                                </Typography>
                                                {/* <b>Le &nbsp;{formatDateByCountryCode(localAppointment.end, 'fr', DateFormatTypes.LONG_FORMAT_DATETIME)}</b> */}
                                            </Alert>
                                        </Grid>
                                    </Grid>}
                            </CardContent>
                        </Card>

                        {/** Alerte sur l'état du rendez-vous */}
                        {localAppointment &&
                            <Alert
                                icon={<AppointmentIcon appointment={appointment} withStyle={false}/>}
                                sx={{ mt: 0, px: 2, py: 0, backgroundColor: getEventStateColor(localAppointment.state) }}
                                severity={getAlertSeverity(localAppointment.state)} variant='filled'>
                                {getEventStateLabel(localAppointment.state)}
                            </Alert>
                        }

                       {/** Si une empreinte bancaire est requise */}
                        {appointment.state === EventState.REQUIRES_PAYMENT_AUTHORIZATION && (
                            <Grid container sx={{mb: 2, mt: 2}}>
                                <Grid item xs={3}>
                                    <img style={{ width: '70px'}} src={PaymentAuthorizationImg} alt={'Empreinte CB Image'} />
                                </Grid>

                                <Grid item xs={9} style={flexCenter}>
                                    <Typography
                                        variant='body2'
                                        color={theme.palette.text.secondary}
                                        sx={{ mt: 1}}
                                    >
                                        Une empreinte de carte est requise pour confirmer ce rendez-vous (consultez vos email).
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} style={flexCenter} sx={{mt: 2}}>
                                    <LoadingButton loading={loading} onClick={(e) => handleResendPaymentAuthorization(e)} variant='contained'
                                        sx={{ color: getEventStateColor(localAppointment.state), width: isMobile ? '100%' : '80%', height: '100%', py: 2,
                                            backgroundColor: getEventStateColor(localAppointment.state), textTransform: 'none',
                                            '&:hover': {
                                                backgroundColor: darken(getEventStateColor(localAppointment.state), 0.2), // Utilise darken pour assombrir la couleur
                                              },
                                         }}>
                                        <Typography sx={{ lineHeight: 1, color: 'white'
                                         }} style={flexCenter}>
                                            💳 &nbsp;Renvoyer la demande par email
                                        </Typography>
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        )}

                        {/** S'il existe une facture adossée au RDV */}
                        {(invoice) &&
                            <Grid container sx={{ mt: 2 }}>
                                <Grid item xs={12}>
                                    {invoice && <Button href={invoice.fileSignedUrl} target="_blank" rel="noopener"  variant='contained'
                                         sx={{ color: getEventStateColor(localAppointment.state), width: isMobile ? '100%' : '80%', height: '100%', py: 2,
                                            backgroundColor: getEventStateColor(localAppointment.state), textTransform: 'none',
                                            '&:hover': {
                                                backgroundColor: darken(getEventStateColor(localAppointment.state), 0.2), // Utilise darken pour assombrir la couleur
                                              },
                                         }}>
                                        <Typography sx={{ lineHeight: 1, color: theme.palette.text.primary }} style={flexCenter}>
                                            🧾 &nbsp;Consulter la facture
                                        </Typography>
                                    </Button>}
                                </Grid>

                            </Grid>
                        }

                        {/** S'il existe un devis (un montant OU un fichier) */}
                        {(localAppointment.quoteAmount || quote) && localAppointment.state !== EventState.DONE &&
                            <Grid container sx={{ mt: 2, py: 2, border: 0.5, borderColor: getEventStateColor(localAppointment.state) }}>
                                <Grid item xs={quote && !localAppointment.quoteAmount ? 12 : 6}>
                                    {quote && <Link href={quote.fileSignedUrl} target="_blank" rel="noopener">
                                        <Typography sx={{ lineHeight: 1, color: theme.palette.primary.dark }} style={flexCenter}>
                                            VOIR LE DEVIS
                                            {/*Devis du <>{formatDateByCountryCode(quote.creationDate, 'fr', DateFormatTypes.SHORT_FORMAT_DATE)}</> */}
                                        </Typography>
                                    </Link>}
                                </Grid>
                                {localAppointment.quoteAmount && <Grid item xs={quote ? 6 : 12}>
                                    <Typography sx={{ lineHeight: 1, fontSize: '1.1rem' }} >
                                        <span><b>{formatEurosCurrency(localAppointment.quoteAmount)}</b>&nbsp; T.T.C</span>
                                    </Typography>
                                </Grid>}
                                {localAppointment.state === EventState.ACCEPTED &&
                                    <>
                                        <Grid item xs={6} sx={{ mt: 3 }}>
                                            <Button onClick={handleOnApproveQuote} color="primary" sx={{ width: '80%' }} variant='contained'>
                                                ACCEPTER
                                            </Button>
                                        </Grid>
                                        <Grid item xs={6} sx={{ mt: 3 }}>
                                            <Button onClick={handleOnRejectQuote} color="inherit" sx={{ width: '80%' }} variant='contained'>
                                                REFUSER
                                            </Button>
                                        </Grid>
                                    </>}
                                {localAppointment.state === EventState.SCHEDULED &&
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>
                                            Devis accepté.
                                        </Typography>
                                    </Grid>
                                }
                                {localAppointment.state === EventState.REJECTED &&
                                    <Grid item xs={12} sx={{ mt: 2 }}>
                                        <Typography>
                                            Refus du devis enregistré.
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        }

                        {/** Rappel infos du GARAGE */}
                        {garage &&
                            <>
                                <Typography variant="h6" component="div" align="center" sx={{ mt: 4, mb: 1, fontSize: '18px' }} color="text.secondary" >
                                    COORDONNÉES DU GARAGE
                                </Typography>
                                <GarageSimpleCard garage={garage} showBorder={true} onClick={handleOnClickGarage} />
                            </>
                        }

                        {/** Eventuelles notes de RDV */}
                        {/*       {localAppointment.notes && 
                            <>
                                <Typography variant="h6" component="div" align="center" sx={{ mt: 4, mb:1, fontSize: '18px' }} color="text.secondary" >
                                    NOTES DE RENDEZ-VOUS
                                </Typography>
                                <Card variant='outlined' sx={{  overflow: 'visible', mt: 1 }} style={flexStart}>
                                    <CardContent sx={{ pt: 2, pb: 0}}>
                                        <Typography>{localAppointment.notes}</Typography>
                                    </CardContent>
                                </Card>
                            </>
                        } */}

                        <Loader loading={loading} />
                    </Box>
                </MovaDialog>
            }

            {openRejectQuote && <ConfirmationDialog
                open={openRejectQuote}
                onClose={handleCancelRejectQuote}
                onConfirm={handleConfirmRejectQuote}
                title="Confirmation"
                message={
                    <Grid container>
                        <Grid item xs={12} >
                            <Typography variant='subtitle1'>
                                Confirmez-vous le <b>refus</b> du devis ?
                            </Typography>
                        </Grid>
                        {/* <Grid xs={12}>
                                <TextField
                                    id="reminder-message"
                                    label="Votre message ..."
                                    value={form.comment?.value}
                                    onChange={(e) => setForm(prevForm => (
                                        { ...prevForm, ["comment"] : { ...prevForm["comment"], value: e.target.value }})) }
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    error={Boolean(form.comment?.error)}
                                    helperText={form.comment?.error}
                                    fullWidth
                                    required
                                    sx={{ mb: 3 }}
                                />
                            </Grid> */}
                    </Grid>
                } />}

            {openConfirmAppointmentCancel &&
                <ConfirmationDialog
                    open={openConfirmAppointmentCancel}
                    onClose={handleCloseConfirmAppointmentCancel}
                    onConfirm={handleConfirmAppointmentCancel}
                    message={<Grid container>
                        <Grid item xs={12} >
                            <Typography variant='subtitle1'>
                                Souhaitez-vous vraiment <b>annuler</b> ce rendez-vous ?
                            </Typography>
                        </Grid>
                        {/* <Grid xs={12}>
                                    <TextField
                                        id="reminder-message"
                                        label="Votre message ..."
                                        value={form.comment?.value}
                                        onChange={(e) => setForm(prevForm => (
                                            { ...prevForm, ["comment"] : { ...prevForm["comment"], value: e.target.value }})) }
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        error={Boolean(form.comment?.error)}
                                        helperText={form.comment?.error}
                                        fullWidth
                                        required
                                        sx={{ mb: 3 }}
                                    />
                                </Grid> */}
                    </Grid>
                    }
                    sx={{ margin: 0 }}
                />
            }

            {openGarageDialog &&
                <GarageDialog open={openGarageDialog} garageId={selectedGarage} onClose={handleOnCloseGarageDialog} />
            }
        </>
    );
}

export default AppointmentDetailsDialog;
