import {
	Garage,
	MovaAppType,
	MovaDialog,
	MovaSignUp,
	MovaUserSignUpForm,
	MovaVehicleTireField,
	User,
	UserService as CoreUserService,
	Vehicle,
	VehicleTire,
	getApplicationShortLabel,
	readCookie,
	validatePhoneNumber,
	DialogForgotPassword,
	AuthenticationService,
	MovaLoginForm,
	MovaFormField,
	validateField,
	Loader,
} from "@movalib/movalib-commons";
import { useState, type FC, CSSProperties, useEffect, useRef } from "react";
import {
	Alert,
	AlertColor,
	Box,
	Button,
	Card,
	Checkbox,
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	Link,
	SelectChangeEvent,
	TextField,
	Typography,
	darken,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { flexCenter } from "../../helpers/Tools";
import PhoneIcon from "@mui/icons-material/PhoneAndroidRounded";
import UserService from "../../services/UserService";
import { setUserData, setUserVehicles } from "../../slices/userSlice";
import { setSnackbar } from "../../slices/snackbarSlice";
import { useDispatch } from "react-redux";
import VehicleSimpleCard from "../../components/VehicleSimpleCard";
import NextIcon from "@mui/icons-material/ArrowForwardIosRounded";
import AppointmentSlotsDialog from "./AppointmentSlotsDialog";
import { useLocation } from "react-router-dom";
import AppointmentsIcon from "@mui/icons-material/TodayRounded";
import Logger from "../../helpers/Logger";
import EmptyCard from "../../components/EmptyCard";
import AddVehicleDialog from "../AddVehicleDialog";
import { PrestationRequest } from "../../helpers/Types";
import InfoIcon from "@mui/icons-material/Info";
import AppointmentVehicleDialog from "./AppointmentVehicleDialog";
import { COOKIE_INDIVIDUAL_TOKEN } from "@movalib/movalib-commons/dist/src/helpers/CookieUtils";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { MovaValidationForm } from "@movalib/movalib-commons/dist/src/helpers/Types";

// ATTENTION : s'assurer de la présence des documents suivants à la racine du composant porteur (dossier 'public')
const CGUPath: string = "/Movalib_CGU.pdf";

interface AppointmentCustomerDialogProps {
	open: boolean;
	garage: Garage;
	prestations: PrestationRequest[];
	otherReason?: string;
	onClose: () => void;
}

const initialLoginFormState = {
	email: { value: "", isValid: true },
	password: { value: "", isValid: true },
	phoneNumberEmail: { value: "", isValid: true },
};

const initialCustomerFormState = {
	password: { value: "", isValid: true },
	confirmation: { value: "", isValid: true },
	securityCode: { value: "", isValid: true },
	phoneNumber: { value: "", isValid: true },
	acceptsTerms: { value: false, isValid: true },
};

const AppointmentCustomerDialog: FC<AppointmentCustomerDialogProps> = ({
	open,
	garage,
	prestations,
	otherReason,
	onClose,
}) => {
	const theme = useTheme();
	const location = useLocation();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useDispatch();
	const [alertMessage, setAlertMessage] = useState<string>("");
	// La sévérité est initialiée à "error" par défaut
	const [alertSeverity, setAlertSeverity] = useState<AlertColor>("error");
	const loading = useRef<boolean>(false);
	const [customerForm, setCustomerForm] = useState<MovaValidationForm>(
		initialCustomerFormState,
	);
	const [openPhoneNumberInfo, setOpenPhoneNumberInfo] = useState(false);
	const [openNotMeInfo, setOpenNotMeInfo] = useState(false);
	const [openAppointmentVehicle, setOpenAppointmentVehicle] =
		useState<boolean>(false);
	const [localOpen, setLocalOpen] = useState<boolean>(false);
	const [existsUser, setExistsUser] = useState<User | undefined>(undefined);
	const [showPassword, setShowPassword] = useState(false);
	const [openForgotPassword, setOpenForgotPassword] = useState(false);
	const [loginForm, setLoginForm] = useState<MovaLoginForm>(
		initialLoginFormState,
	);
	const [showSecurityCode, setShowSecurityCode] = useState(false);
	const [loadingCreateAccount, setLoadingCreateAccount] = useState(false);

	// Si l'utilisateur n'est pas authentifié
	const isAuthenticated: boolean = Boolean(readCookie(COOKIE_INDIVIDUAL_TOKEN));

	useEffect(() => {
		setLocalOpen(open);
	}, [open]);

	const dialogTitleStyle: CSSProperties = {
		color: darken(theme.palette.primary.main, 0.2),
		fontWeight: 700,
	};

	const handleInputChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | null,
	): void => {
		if (e) {
			const fieldName: string = e.target.name;
			let fieldValue: string = e.target.value;
			const newField: MovaFormField = {
				[fieldName]: { value: fieldValue, isValid: true },
			};

			// On autorise uniquement les chiffres pour le n° de téléphone
			if (fieldName == "phoneNumber") {
				fieldValue = fieldValue.replace(/[^0-9]/g, "");
				// Limiter la longueur à 10 caractères
				if (fieldValue.length > 10) {
					fieldValue = fieldValue.substring(0, 10);
				} else if (fieldValue.length == 10) {
					// Contrôle existance d'un compte utilisateur
					handleCheckUserAccount(fieldValue);
				} else {
					setExistsUser(undefined);
				}
			}

			if (fieldName == "phoneNumberEmail") {
				setLoginForm({ ...loginForm, ...newField });
			} else {
				setCustomerForm({ ...customerForm, ...newField });
			}
		}
	};

	const handleCheckUserAccount = (phoneNumber: string) => {
		loading.current = true;

		try {
			CoreUserService.existsByPhoneNumber(phoneNumber).then((response) => {
				Logger.info(response);

				if (response.success) {
					setExistsUser(response.data);
					// On fait persister les données utilisateur dans le cache du navigateur
					dispatch(setUserData(response.data));
				} else {
					setExistsUser(undefined);
				}
			});
		} catch (error) {
			console.error("Error occurred during submission:", error);
		} finally {
			loading.current = false;
		}
	};

	const handleSignUpSubmit = async (form: MovaUserSignUpForm) => {
		loading.current = true;

		try {
			setLoadingCreateAccount(true);
			// On prépare la query
			let query = {
				email: form.email.value,
				password: form.password.value,
				firstname: form.firstname.value,
				lastname: form.lastname.value,
				phoneNumber: customerForm.phoneNumber.value, // Attention on récupère le champ de saisie local, pas celui du MovaUserSignUpForm
				smsActivation: true, // Nous demandons une activation par SMS (on valide ainsi le n° de téléphone)
			};

			UserService.signUp(query)
				.then((response) => {
					// Affichage notification utilisateur
					dispatch(
						setSnackbar({
							open: true,
							message:
								"Votre compte est créé ! Saisissez le code de sécurité reçu par SMS",
							severity: "success",
						}),
					);
					// On refresh le user local
					handleCheckUserAccount(customerForm.phoneNumber.value);
					// On active la saisie du code de sécurité
					setShowSecurityCode(true);
					// On reporte le mot de passe pour permettre le login qui fait suite à la vérification du code de sécurité
					setCustomerForm((customerForm) => ({
						...customerForm,
						["password"]: {
							...customerForm["password"],
							value: form.password.value,
							isValid: true,
						},
					}));
				})
				.catch((error) => {
					Logger.error(error);
					setAlertMessage(error);
				})
				.finally(() => {
					setLoadingCreateAccount(false);
				});
		} catch (error) {
			console.error("Error occurred during submission:", error);
		} finally {
			loading.current = false;
		}
	};

	const handleOnCloseAppointmentVehicle = () => {
		// En fonction du scénario, on reste sur cette boite de dialog où bien ous repartons à la sélection des prestations (on appelle le calback directement)
		if (isAuthenticated) {
			onClose();
		} else {
			setOpenAppointmentVehicle(false);
			setLocalOpen(true);
		}
	};

	const purgeLocalState = () => {
		setExistsUser(undefined);
		setCustomerForm(initialCustomerFormState);
		setLoginForm(initialLoginFormState);
		setShowSecurityCode(false);
	};

	const handleOnClose = () => {
		purgeLocalState();
		onClose();
	};

	const validateLogin = () => {
		let newForm: MovaValidationForm = { ...customerForm };

		// Validator pour les champs obligatoires
		newForm.phoneNumber = validateField(
			customerForm.phoneNumber,
			(value) => !!value,
			"Champ obligatoire",
		);
		newForm.password = validateField(
			customerForm.password,
			(value) => !!value,
			"Champ obligatoire",
		);

		// Validator pour le mot de passe
		newForm.password = validateField(
			customerForm.password,
			(value) =>
				value.length >= 10 && /[a-z]/.test(value) && /[A-Z]/.test(value),
			"Votre mot de passe doit faire au moins 10 caractères et contenir une majuscule et une minuscule minimum",
		);

		setCustomerForm(newForm);

		return newForm.password.isValid && newForm.phoneNumber.isValid;
	};

	const validateCreatePassword = () => {
		let newForm: MovaValidationForm = { ...customerForm };

		// Validator pour les champs obligatoires
		newForm.password = validateField(
			customerForm.password,
			(value) => !!value,
			"Champ obligatoire",
		);
		newForm.confirmation = validateField(
			customerForm.confirmation,
			(value) => !!value,
			"Champ obligatoire",
		);

		// Validator pour le mot de passe
		newForm.password = validateField(
			customerForm.password,
			(value) =>
				value.length >= 10 && /[a-z]/.test(value) && /[A-Z]/.test(value),
			"Votre mot de passe doit faire au moins 10 caractères et contenir une majuscule et une minuscule minimum",
		);

		// Second validator pour le mot de passe (confirmation)
		newForm.confirmation = validateField(
			customerForm.confirmation,
			(value) => value === customerForm.password.value,
			"Les mots de passe doivent être identiques",
		);

		// Validator pour les CGU
		newForm.acceptsTerms = validateField(
			customerForm.acceptsTerms,
			(value) => Boolean(value),
			"Vous devez accepter les termes de nos CGU",
		);

		setCustomerForm(newForm);

		return (
			newForm.password.isValid &&
			newForm.confirmation.isValid &&
			newForm.acceptsTerms.isValid
		);
	};

	const validateSecurityCode = () => {
		let newForm: MovaValidationForm = { ...customerForm };

		// Validator pour le code de sécurité
		newForm.securityCode = validateField(
			customerForm.securityCode,
			(value) => value.length === 6,
			"Le code doit contenir 6 chiffres",
		);
		setCustomerForm(newForm);

		return newForm.securityCode.isValid;
	};

	const handleOnClickNext = () => {
		Logger.info(existsUser);
		// En fonction du cas nous déclenchons des processus spécifiques
		if (existsUser) {
			if (existsUser.hasPassword && !existsUser.isActive) {
				// Compte créé mais à activer
				Logger.info("test");
				// Et activer son compte via son n° de téléphone
				if (!showSecurityCode) {
					sendSecurityCode();
				} else {
					activateUserAccount();
				}
			} else if (existsUser.hasPassword) {
				if (validateLogin()) {
					login();
				}
			} else {
				// Sinon, il doit définir son mot de passe
				if (validateCreatePassword()) {
					// Et activer son compte via son n° de téléphone
					if (!showSecurityCode) {
						sendSecurityCode();
					} else {
						activateUserAccount();
					}
				}
			}
		}
	};

	const activateUserAccount = () => {
		if (validateSecurityCode()) {
			let req = {
				securityCode: customerForm.securityCode.value,
				password: customerForm.password.value,
			};

			CoreUserService.validateAccount(req)
				.then((response) => {
					Logger.info(response);

					if (response.success) {
						// Le compte est validé, nous pouvons authentifier l'utilisateur (puis il sera redirigé vers le choix du véhicule)
						login();
					} else {
						setExpiredCode((response as any).data.expiredCode);
						const message = (response as any).data.unknownCode
							? "Code de sécurité non reconnu"
							: (response as any).data.expiredCode
								? "Code de sécurité expiré"
								: "Activation de votre compte impossible";
						dispatch(
							setSnackbar({ open: true, message: message, severity: "error" }),
						);
					}
				})
				.catch((error) => {
					Logger.error(error);
				});
		}
	};
	const [expiredCode, setExpiredCode] = useState<boolean>(false);
	const sendSecurityCode = () => {
		if (customerForm.phoneNumber.isValid) {
			let req = {
				phoneNumber: customerForm.phoneNumber?.value,
			};

			CoreUserService.resendSecurityCode(req)
				.then((response) => {
					Logger.info(response);

					if (response.success) {
						setShowSecurityCode(true);
					} else {
						dispatch(
							setSnackbar({
								open: true,
								message:
									response.error ??
									"Envoi du code de sécurité impossible, vérifiez votre numéro",
								severity: "error",
							}),
						);
						setShowSecurityCode(false);
					}
				})
				.catch((error) => {
					Logger.error(error);
				});
		}
	};

	const login = () => {
		loading.current = true;

		try {
			Logger.info(customerForm);
			AuthenticationService.login(
				MovaAppType.INDIVIDUAL,
				customerForm.phoneNumber.value,
				customerForm.password.value,
			)
				.then((response) => {
					if (response.success) {
						Logger.info(response.data);
						// On fait persister les données utilisateur dans le cache du navigateur
						dispatch(setUserData(response.data));

						// On passe au choix du véhicule
						setLocalOpen(false);
						setOpenAppointmentVehicle(true);
					} else {
						dispatch(
							setSnackbar({
								open: true,
								message: response.error ?? "Connexion impossible",
								severity: "error",
							}),
						);
					}
				})
				.catch((error) => {
					Logger.error(error);
				});
		} catch (error) {
			console.error("Error occurred during submission:", error);
		} finally {
			loading.current = false;
		}
	};

	const handleClickShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const handleOnClickForgotPassword = (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
	) => {
		e.preventDefault();
		setLocalOpen(false);
		setOpenForgotPassword(true);
	};

	const handleCancelForgotPassword = () => {
		setOpenForgotPassword(false);
		setLocalOpen(true);
	};

	const handleSubmitForgotPassword = () => {
		setOpenForgotPassword(false);
		setLocalOpen(true);

		if (loginForm.phoneNumberEmail.value) {
			let query = {
				phoneEmail: loginForm.phoneNumberEmail.value,
				appType: "MOVALIB",
			};

			AuthenticationService.forgotPassword(query).then((response) => {
				if (response.success) {
					dispatch(
						setSnackbar({
							open: true,
							message:
								response.data ??
								"Mot de passe réinitialisé ! (consultez vos messages)",
							severity: "success",
						}),
					);
				} else {
					dispatch(
						setSnackbar({
							open: true,
							message:
								response.error ??
								"Problème lors de la réinitialisation du mot de passe, contactez Movalib",
							severity: "error",
						}),
					);
				}
			});
		}
	};

	const handleCheckboxChange = (
		e: SelectChangeEvent<string>,
		checked: boolean,
	): void => {
		const fieldName: string = e.target.name;
		const fieldValue: boolean = checked;
		const newField: MovaFormField = {
			[fieldName]: { value: fieldValue, isValid: true },
		};

		setCustomerForm({ ...customerForm, ...newField });
	};

	const isNextStepAuthorized = (): boolean => {
		return Boolean(existsUser);
	};
	const garageLogo = garage?.logo ?? null;
	
	return (
		<>
			{garage && !isAuthenticated && (
				<MovaDialog
					fullScreen={isMobile}
					open={localOpen}
					onClose={handleOnClose}
					leafImageColor="yellow"
					title={
						<>
						<Typography sx={{ textAlign:'center', fontSize: 20, flexGrow: 1, pl: '20px' , pr: '20px'}} style={dialogTitleStyle}>
							{garage.name}
						</Typography>
						{ garageLogo && <img src={garageLogo} alt="Logo du Garage" style={{ maxHeight: '70px',  right: 0, top: '0px' }} />}
						</>
					}
					titleStyle={dialogTitleStyle}
					actions={
						!(
							existsUser == undefined &&
							customerForm.phoneNumber.value.length === 10 &&
							!loading.current
						) && (
							<Button
								onClick={handleOnClickNext}
								color="primary"
								sx={{ width: "90%" }}
								variant="contained"
								disabled={!isNextStepAuthorized()}
							>
								<NextIcon sx={{ mr: 1 }} />
								Suivant
							</Button>
						)
					}
				>
					<Grid
						container
						sx={{ width: "100%", minHeight: "400px", flexDirection: "column" }}
					>
						{!existsUser && customerForm.phoneNumber.value.length < 10 && (
							<Grid item xs={12}>
								<Typography
									variant="h6"
									color={theme.palette.text.primary}
									style={flexCenter}
									sx={{ mt: 2, mb: 2 }}
								>
									<PhoneIcon sx={{ mr: 2 }} />
									<b>Votre numéro de téléphone ?</b>
								</Typography>
							</Grid>
						)}
						{existsUser && !showSecurityCode && (
							<Grid item xs={12}>
								<Typography
									variant="h6"
									sx={{
										width: "100%",
										textTransform: "none",
										textAlign: "center",
										mb: 1,
										mt: 1,
									}}
								>
									👋&nbsp;&nbsp;Bonjour{" "}
									<b>
										{existsUser.firstname}&nbsp;
										{<>{existsUser.lastname.substring(0, 1)}.</>}
									</b>
								</Typography>
								<Typography variant="body2" sx={{ mb: 2 }}>
									{existsUser.hasPassword
										? "Saisissez votre mot de passe pour continuer"
										: "Vous devez activer votre compte, commencer par définir votre mot de passe"}
								</Typography>
								<Grid item xs={12} sx={{ mb: 1 }}>
									<Link
										variant="body2"
										color="text.secondary"
										onClick={(e) => setOpenNotMeInfo(!openNotMeInfo)}
										sx={{ cursor: "pointer" }}
									>
										<i>
											Je ne suis pas{" "}
											<b>
												{existsUser.firstname}&nbsp;
												{existsUser.lastname.substring(0, 1)}.
											</b>
										</i>
									</Link>
									{openNotMeInfo && (
										<Alert severity="info" variant="standard" sx={{ mt: 2 }}>
											Chez Movalib ce numéro est attribué à{" "}
											{existsUser.firstname}&nbsp;
											{existsUser.lastname.substring(0, 1)}. Si ce numéro vous
											appartient, écrivez-nous à{" "}
											<a href="contact@movalib.com">contact@movalib.com</a>.
										</Alert>
									)}
								</Grid>
							</Grid>
						)}
						{existsUser == undefined &&
							customerForm.phoneNumber.value.length === 10 &&
							!loading.current && (
								<Grid item xs={12}>
									<Typography
										variant="h6"
										style={flexCenter}
										sx={{
											width: "100%",
											textTransform: "none",
											textAlign: "center",
											mb: 1,
											mt: 1,
										}}
									>
										<b>Première Connexion</b>
									</Typography>
									<Typography variant="body2" sx={{ mb: 2 }}>
										Vous devez créer votre compte Movalib
									</Typography>
								</Grid>
							)}

						{!showSecurityCode && (
							<>
								<Grid item xs={12}>
									<TextField
										autoFocus
										type="tel"
										margin="normal"
										required
										fullWidth
										id="phoneNumber"
										label="N° de téléphone"
										name="phoneNumber"
										autoComplete="tel"
										onChange={(e) => handleInputChange(e)}
										value={customerForm.phoneNumber?.value}
										error={Boolean(customerForm.phoneNumber?.error)}
										helperText={customerForm.phoneNumber?.error}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														edge="end"
														onClick={() =>
															setOpenPhoneNumberInfo(!openPhoneNumberInfo)
														}
													>
														<InfoIcon />
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
									{openPhoneNumberInfo && (
										<Alert severity="info" variant="standard">
											Indispensable en cas d'imprévu avec votre véhicule.
										</Alert>
									)}
								</Grid>
								{existsUser && (
									<Grid item xs={12}>
										<TextField
											margin="normal"
											autoFocus={Boolean(existsUser)}
											required
											fullWidth
											name="password"
											label="Mot de passe"
											type={showPassword ? "text" : "password"}
											id="password"
											autoComplete="current-password"
											onChange={(e) => handleInputChange(e)}
											value={customerForm.password.value}
											error={!customerForm.password.isValid}
											helperText={
												existsUser.hasPassword
													? customerForm.password.error
													: "10 caractères minimum, 1 majuscule, 1 minuscule"
											}
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton
															edge="end"
															onClick={handleClickShowPassword}
														>
															{showPassword ? (
																<VisibilityOff />
															) : (
																<Visibility />
															)}
														</IconButton>
													</InputAdornment>
												),
											}}
										/>
									</Grid>
								)}
								{existsUser && !existsUser?.hasPassword && (
									<Grid container>
										<Grid item xs={12}>
											<TextField
												margin="normal"
												required
												fullWidth
												name="confirmation"
												label="Confirmation mot de passe"
												type={showPassword ? "text" : "password"}
												id="confirmation"
												onChange={(e) => handleInputChange(e)}
												value={customerForm.confirmation.value}
												error={!customerForm.confirmation.isValid}
												helperText={
													Boolean(customerForm.confirmation.error)
														? customerForm.confirmation.error
														: ""
												}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																edge="end"
																onClick={(e) =>
																	setShowPassword((prev) => !prev)
																}
															>
																{showPassword ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Grid>
										<Grid
											item
											xs={12}
											sx={{ mt: 1, display: "flex", justifyContent: "center" }}
										>
											<FormControl error={!customerForm.acceptsTerms.isValid}>
												<FormControlLabel
													control={
														<Checkbox
															name="acceptsTerms"
															color="primary"
															checked={customerForm.acceptsTerms.value}
															onChange={(e, checked) =>
																handleCheckboxChange(e, checked)
															}
															sx={{ pr: 2 }}
														/>
													}
													label={
														<span>
															J'accepte les{" "}
															<a
																href={CGUPath}
																target="_blank"
																style={{ color: theme.palette.primary.main }}
															>
																Conditions Générales d'Utilisation
															</a>
														</span>
													}
												/>
												<FormHelperText>
													{customerForm.acceptsTerms.error}
												</FormHelperText>
											</FormControl>
										</Grid>
									</Grid>
								)}
							</>
						)}

						{showSecurityCode && (
							<Grid container sx={{ mt: 2 }}>
								<Grid item xs={12}>
									<Typography variant="body2" sx={{ mb: 2 }}>
										Saisissez le code de vérification reçu par SMS
									</Typography>
								</Grid>
								<Grid item xs={12} sx={{ mt: 1 }}>
									<TextField
										label="Code reçu par SMS"
										required
										fullWidth
										name="securityCode"
										variant="outlined"
										autoFocus
										value={customerForm.securityCode?.value}
										error={!customerForm.securityCode?.isValid}
										helperText={
											Boolean(customerForm.securityCode?.error)
												? customerForm.securityCode?.error
												: null
										}
										onChange={(e) => handleInputChange(e)}
										inputProps={{
											maxLength: 6,
											inputMode: "numeric", // Affiche un clavier numérique sur les appareils mobiles
											pattern: "[0-9]*", // Permet seulement la saisie de chiffres
										}}
									/>
								</Grid>
								{/* {expiredCode && <Grid item xs={12}>
                                        <Button onClick={sendSecurityCode} color="primary" sx={{ width: '100%', mt: 2 }} variant='contained'>
                                            Renvoyer le code
                                        </Button> 
                                        </Grid>} */}
							</Grid>
						)}

						{existsUser?.hasPassword && !showSecurityCode && (
							<Grid item xs={12} sx={{ mt: 1 }}>
								<Link
									variant="body2"
									color="text.secondary"
									onClick={(e) => handleOnClickForgotPassword(e)}
									sx={{ cursor: "pointer" }}
								>
									J'ai oublié mon mot de passe
								</Link>
							</Grid>
						)}

						{existsUser == undefined &&
							customerForm.phoneNumber.value.length === 10 &&
							!loading.current && (
								<MovaSignUp
									loading={loadingCreateAccount}
									movaAppType={MovaAppType.INDIVIDUAL}
									showHeaderLogo={false}
									showCopyright={false}
									showLeafs={false}
									showLoginButton={false}
									disableGutters={true}
									onSubmit={handleSignUpSubmit}
									alertMessage={alertMessage}
									alertSeverity={alertSeverity}
									usePhoneNumber={false}
								/>
							)}
					</Grid>
				</MovaDialog>
			)}

			{openForgotPassword && (
				<DialogForgotPassword
					openForgotPassword={openForgotPassword}
					setOpenForgotPassword={setOpenForgotPassword}
					movaAppType={MovaAppType.INDIVIDUAL}
					form={loginForm}
					handleInputChange={handleInputChange}
					handleSubmitForgotPassword={handleSubmitForgotPassword}
					onClose={handleCancelForgotPassword}
				/>
			)}

			<AppointmentVehicleDialog
				open={openAppointmentVehicle}
				garage={garage}
				prestations={prestations}
				otherReason={otherReason}
				onClose={handleOnCloseAppointmentVehicle}
			/>

			<Loader loading={loading.current} />
		</>
	);
};

export default AppointmentCustomerDialog;
