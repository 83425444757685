import {
	Garage,
	MovaDialog,
	PartsApplicationType,
	Prestation,
	Vehicle,
	VehicleTire,
	formatVehicleTire,
	getApplicationShortLabel,
	getApplicationsShortLabels,
} from "@movalib/movalib-commons";
import {
	Alert,
	Box,
	Button,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Grid,
	TextField,
	Typography,
	alpha,
	darken,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { useEffect, type CSSProperties, type FC, useState } from "react";
import { useDispatch } from "react-redux";
import ConfirmIcon from "@mui/icons-material/Check";
import {
	calculateTotalDowntimeRequested,
	flexCenter,
	formatLocalDateToISOString,
	getLongFormattedDateTime,
} from "../../helpers/Tools";
import AppointmentsIcon from "@mui/icons-material/TodayRounded";
import SmsIcon from "@mui/icons-material/Sms";
import GarageService from "../../services/GarageService";
import { setSnackbar } from "../../slices/snackbarSlice";
import { useHistory } from "react-router-dom";
import Logger from "../../helpers/Logger";
import { PrestationRequest } from "../../helpers/Types";
import TirePicture from "../../assets/images/flan_pneu.png";
import {
	FLAT_PRESTATION_CODE,
	TIRE_PRESTATION_CODE,
} from "../../helpers/Constants";
import LoadingButton from "@mui/lab/LoadingButton";
import { calculateHueRotation, displayChipPrestation } from "./AppointmentPrestationsDialog";
import PaymentAuthorizationImg from '../../assets/images/payment_authorization.png';
import MessageImg from '../../assets/images/message.png';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import 'react-quill/dist/quill.snow.css';

interface AppointmentSummaryDialogProps {
	open: boolean;
	garage: Garage;
	prestations: PrestationRequest[];
	appointmentDate: Date;
	onClose: () => void;
	vehicle: Vehicle;
	tireSize?: VehicleTire;
	otherReason?: string;
}

const AppointmentSummaryDialog: FC<AppointmentSummaryDialogProps> = ({
	open,
	garage,
	prestations,
	otherReason,
	vehicle,
	tireSize,
	appointmentDate,
	onClose,
}) => {
	const customStyle = JSON.parse(garage.customStyle || '{}');

	const additionalDescription = customStyle.additionalDescription ?? '';
	const garageLogo = garage.logo ?? null;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const dispatch = useDispatch();
	const history = useHistory();
	const [localOpen, setLocalOpen] = useState<boolean>(open);
	const [showTireSize, setShowTireSize] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [note, setNote] = useState<string>("");
	const [otherChoice, setOtherChoice] = useState<Prestation>();
	const [displayAskLoanVehicle, setDisplayAskLoanVehicle] = useState<boolean>(garage.loanerVehicleRequestActive ?? false);
	const [askLoanVehcle, setAskLoanVehicle] = useState<boolean>(false);
	const dialogTitleStyle: CSSProperties = {
		color: darken(theme.palette.primary.main, 0.2),
		fontWeight: 700,
	};
	function isValidBase64(str:string) {
		try {
			return btoa(atob(str)) === str; // Vérifie si l'encodage et décodage retournent la même chose
		} catch (e) {
			return false; // Erreur si la chaîne n'est pas valide
		}
	  }
	  function base64ToUtf8(base64: string) {
		if(!isValidBase64(base64)) {
			return base64;
		}
		const binaryStr = isValidBase64(base64) ? atob(base64) : base64;
		const bytes = new Uint8Array([...binaryStr].map(c => c.charCodeAt(0)));
		const decoder = new TextDecoder();
		return decoder.decode(bytes);
	  }
	useEffect(() => {
		console.log("AppointmentSummaryDialog useEffect", prestations);
		if (
			prestations.map((p) => p.code).includes(TIRE_PRESTATION_CODE) ||
			prestations.map((p) => p.code).includes(FLAT_PRESTATION_CODE)
		)
		{
			// on affiche la dimension des pneumatiques s'il s'agit d'une prestation pertinente et si les dimension est disponible
			setShowTireSize(tireSize ? true : false);
		}
		if (garage)
		{
			setOtherChoice(garage.prestations.filter((p) => p.code === "OTHER")[0]);
		}

		const currentIdPresta = prestations.map((p) => p.id);
		const currentPrestations = garage.prestations.filter((presta) => { return currentIdPresta.includes(presta.id) });
		const totalDownTime = currentPrestations.map((p) => p.downtime).reduce((a, b) => a + b, 0);

		if (garage.loanerVehicleFastServiceExcluded)
		{
			if (garage.fastServiceThreshold < totalDownTime)
			{
				setDisplayAskLoanVehicle(true);
			} else
			{
				setDisplayAskLoanVehicle(false);
			}
		} 

		setLocalOpen(open);
	}, [open]);

	const purgeLocalState = () => { };

	const handleOnClose = () => {
		purgeLocalState();
		setLocalOpen(false);
		onClose();
	};

	const handleOnClickValidate = () => {
		if (garage && prestations && vehicle && appointmentDate)
		{
			// Pour chaque prestation

			let req = {
				//startDate: appointmentDate,
				// IMPORTANT : permet de transmettre le TimeZone correspond à l'horaire choisit, l'API réalise des ajustements selon la zone
				startDate: formatLocalDateToISOString(appointmentDate, garage.timezone),
				prestations: prestations,
				vehicleId: vehicle.id,
				tireSize: tireSize,
				otherReason: otherReason,
				notes: note,
				garageVehicleRequest: askLoanVehcle,
			};
			setLoading(true);
			Logger.info(req);
			GarageService.createGarageAppointment(dispatch, garage.id, req)
				.then((response) => {
					Logger.info(response);

					// Affichage notification utilisateur
					dispatch(
						setSnackbar({ open: true, message: response, severity: "success" }),
					);

					// Dernière étape, une fois la demande de RDV enregistrée on retourne à la Home page
					history.push("/home");
				})
				.catch((error) => {
					Logger.error(error);
					dispatch(
						setSnackbar({ open: true, message: error, severity: "error" }),
					);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<>
			{garage && prestations && vehicle && appointmentDate && (
				<MovaDialog
					fullScreen={isMobile}
					open={localOpen}
					onClose={handleOnClose}
					leafImageColor="yellow"
					title={
						<>
						<Typography sx={{ textAlign:'center', fontSize: 20, flexGrow: 1, pl: '20px' , pr: '20px'}} style={dialogTitleStyle}>
							{garage.name}
						</Typography>
						{ garageLogo && <img src={garageLogo} alt="Logo du Garage" style={{ maxHeight: '70px', right: 0, top: '0px' }} />}
						</>
					}
					titleStyle={dialogTitleStyle}
					actions={
						<>
							{loading ? (
								<LoadingButton
									sx={{ width: "90%", height: "36px" }}
									loading={loading}
									variant="contained"
									disabled
								/>
							) : (
								<Button
									onClick={handleOnClickValidate}
									color="primary"
									sx={{ width: "90%" }}
									variant="contained"
									disabled={
										!garage &&
										(!prestations || !(prestations.length > 0)) &&
										!vehicle &&
										appointmentDate
									}
								>
									<ConfirmIcon sx={{ mr: 1 }} />
									Valider ma demande
								</Button>
							)}
						</>
					}
				>
					<Typography
						variant="h6"
						component="div"
						color={theme.palette.text.primary}
						style={flexCenter}
						sx={{ mt: 1, mb: 2, textDecoration: "underline" }}
					>
						Votre demande de rendez-vous
					</Typography>
					{displayAskLoanVehicle && (
						<FormGroup sx={{ pl: 2 }}>
							<FormControlLabel
								control={
									<Checkbox
										onChange={(e) => setAskLoanVehicle(e.target.checked)}
										value={askLoanVehcle}
									/>
								}
								label="Demander un véhicule de prêt si disponible"
							/>

						</FormGroup>
					)}
					{/** Rappel infos du RDV */}
					<Card
						variant="outlined"
						sx={{
							backgroundColor: alpha(theme.palette.primary.light, 0.7),
							overflow: "visible",
							m: 2,
							mb: 3,
						}}
					>
						<CardContent sx={{ pt: 2, pb: 0 }}>
							<AppointmentsIcon sx={{ mb: 1 }} />
							{/** Rappel du créneau */}
							<Typography
								variant="subtitle1"
								component="div"
								align="center"
								sx={{ mb: 2, color: theme.palette.primary.contrastText }}
							>
								<b>Le {getLongFormattedDateTime(appointmentDate)}</b>
							</Typography>
							<Typography
								variant="subtitle1"
								component="div"
								align="center"
								sx={{ mb: 1, color: theme.palette.primary.contrastText }}
							>
								<b>
									{vehicle.brand} {vehicle.model} {vehicle.version}
								</b>
							</Typography>
							{/** Rappel des prestations sélectionnées */}
							{garage.prestations &&
								garage.prestations.map((prestation, index) => (
									<Grid container>
										{prestations.map((p) => p.id).includes(prestation.id) && (
											<Grid item xs={12}>
												{prestation.code !== "OTHER" &&
													displayChipPrestation(
														prestation,
														otherChoice!,
														theme,
														prestations
															.filter((p) => p.id === prestation.id)
															.map((p) => p.applications),
													)}

												{prestation.code === "OTHER" && (
													<Typography
														variant="body1"
														sx={{ textAlign: "center", color: theme.palette.primary.contrastText }}
													>
														Autre motif : {otherReason}
													</Typography>
												)}
											</Grid>
										)}
									</Grid>
								))}
							{/** Rappel taille des pneumatiques le cas échéant */}
							{showTireSize && tireSize && (
								<>
									<br />
									<img
										src={TirePicture}
										style={{
											position: "relative",
											width: "40%",
											top: "0px",
											left: "0px",
											zIndex: 200,
										}}
										alt="Icone Voiture"
									></img>
									<br />
									<Typography variant="h6">
										<b>{formatVehicleTire(tireSize)}</b>
									</Typography>
								</>
							)}
						</CardContent>
					</Card>

					<TextField
						sx={{ width: "95%", mb: 2 }}
						id="note"
						label="Commentaire pour le garagiste"
						value={note}
						onChange={(e) => setNote(e.target.value)}
						multiline
						rows={4}
					/>
					{garage.paymentAuthorizationActive === true && garage.paymentAuthorizationMinDowntime !== undefined
						&& (calculateTotalDowntimeRequested(garage, prestations) >= garage.paymentAuthorizationMinDowntime) && (
							<Grid container sx={{ mb: 3 }}>
								<Grid item xs={isMobile ? 12 : 3}>
									<img style={{ width: '80px' }} src={PaymentAuthorizationImg} alt={'Empreinte CB Image'} />
								</Grid>
								<Grid item xs={isMobile ? 12 : 9} style={flexCenter}>
									<Typography
										variant='body2'
										color={theme.palette.text.secondary}
										sx={{ mt: 1 }}
									>
										Une empreinte de carte bancaire va vous être demandée (par email) afin de confirmer votre demande.
									</Typography>
								</Grid>
							</Grid>
						)}
					<Grid container sx={{ mb: 2 }}>
						<Grid item xs={isMobile ? 12 : 3} >
							<img style={{ width: '75px' }} src={MessageImg} alt={'Message Image'} />
						</Grid>
						<Grid item xs={isMobile ? 12 : 9} style={flexCenter}>
							<Typography
								variant='body2'
								color={theme.palette.text.secondary}
								sx={{ mt: 1 }}
							>
								Vous recevrez un message de confirmation une fois votre demande traitée par le garagiste.
							</Typography>
						</Grid>
					</Grid>
					{additionalDescription && <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
						{additionalDescription &&
							<Box sx={{ flex: '100%' }}>
								<div className='ql-editor' style={{ fontFamily: 'Outfit, sans-serif', minWidth: '100%', width: '100%', overflow: 'hidden', textWrap: 'wrap', wordBreak: 'break-word' }}
                          				dangerouslySetInnerHTML={{ __html: base64ToUtf8(additionalDescription) }}
						  />
							</Box>}
					</Box>}
				</MovaDialog>
			)}
		</>
	);
};

export default AppointmentSummaryDialog;
